import { useCallback, useEffect, useState, useRef } from "react";
import { useInjection } from "inversify-react";
import { API } from "../services/HTTP";
import { Form, Button, Spinner, Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Settings } from "../models/Settings";
import _ from 'lodash';
import { Attribute } from "../models/Attribute";
import GeneralSettingsComponent from "../components/settings/GeneralSettingsComponent";
import TimeSeriesSettingsComponent from "../components/settings/TimeSeriesSettingsComponent";
import OnboardingSettingsComponent from "../components/settings/OnboardingSettingsComponent";

export function SettingsPage() {
    const [t] = useTranslation();

    let { domain_id } = useParams();  
    const api = useInjection<API>("API");
    
    const [loading, setLoading] = useState<boolean>(false);
    const [settings, setSettings] = useState<Settings>(); // the active settings
    const [currentSettings, setCurrentSettings] = useState<Settings>(); // the settings that are being modified
    const [errorMessage, setErrorMessage] = useState<{ [key: string]: string }>({});

    const [attributes, setAttributes] = useState<Attribute[]>([{incoming_key: "", storage_key: "", type: ""}]);

    let inKeyInput = useRef<HTMLInputElement>(null);
    let storageKeyInput = useRef<HTMLInputElement>(null);
    let typeInput = useRef<HTMLSelectElement>(null);

    const regexpIncoming = new RegExp('^[a-zA-Z0-9_-]*$');
    const regexpStorage = new RegExp('^[a-z0-9_-]*$');

    const [changesMade, setChangesMade] = useState<boolean>(false);
    const changeSetting = <K extends keyof Settings>(key: K, value: Settings[K]) => {
        setCurrentSettings((currentSettings) => ({
            ...currentSettings!,
            [key]: value
        }));
    };
    const reload = useCallback(async () => {

        setLoading(true);
        const f = async () => {
            if (!domain_id)
                return;

            const response = await api.get<Settings>(`domains/${domain_id}/settings`);
            const fetchedSettings = response.data;
            
            // need to clone, otherwise they both point to the same object in memory
            const clonedSettings = _.cloneDeep(fetchedSettings) 
            
            setSettings(fetchedSettings);
            setCurrentSettings(clonedSettings);
            if (clonedSettings.attributes) {
                setAttributes(clonedSettings.attributes);
            }

        };
        f().finally(() => setLoading(false));

    }, [domain_id])

    useEffect(() => {
        reload();
    }, [reload]);

    useEffect(() => {
        if (!_.isEqual(settings, currentSettings)) {
            setChangesMade(true);
        } else {
            setChangesMade(false)
        }
    }, [settings, currentSettings])

    const editSettings = useCallback(async (settings: Settings) => {
        await api.put(`domains/${domain_id}/settings`, settings)
    }, [domain_id, api])

    const changeAttribute = async (index: number, value: string | string[] | boolean, key: keyof Attribute) => {
        const newAttributes = [...attributes];
        if (key === "display_types") {
            newAttributes[index][key] = value as string[];
        } else if (key === "is_graph_title") {
            newAttributes[index][key] = value as boolean;
        } 
        else {
            newAttributes[index][key] = value as string;
        }
        
        setAttributes(newAttributes);
        const isValid = await validate(index)
        if (isValid) {
            setCurrentSettings({
                ...currentSettings!,
                attributes: newAttributes
            });
        }
    }

    const removeAttribute = (index: number) => {
        const newAttributes = [...attributes];
        newAttributes.splice(index, 1);
        setAttributes(newAttributes)
        setCurrentSettings({
            ...currentSettings!,
            attributes: newAttributes
        });
    }

    const validate = useCallback(async (index: number) => {
        const incoming = inKeyInput.current!.value;
        const storage = storageKeyInput.current!.value;

        const newErrorMessage: { [key: string]: string } = {};

        if (!regexpIncoming.test(incoming)) {
            newErrorMessage[`incoming-${index}`] = ("Attribute name Regex Error");
        }

        if (!regexpStorage.test(storage)) {
            newErrorMessage[`storage-${index}`] = ("Attribute storing name Regex Error");
        }
        const isValid = Object.keys(newErrorMessage).length === 0  
        setErrorMessage(newErrorMessage);
        return isValid
    }, [regexpIncoming, regexpStorage]);

    return <>
        <div className="header-container">
            <div>
            <h1 className="header-title">
                {t("Settings")}
            </h1>
            <small className="header-domain">
                {t("Domain")}: {domain_id}
            </small>
            </div>
        </div>

        <Form style={{padding: "10px"}} onSubmit={(e) => {
            e.preventDefault();
            setLoading(true);

            if(currentSettings == null) {
                throw("Error updating the settings")
            }

            editSettings(currentSettings)
                .then(() => window.location.reload())
                .catch(error => console.error('Error while updating the settings:', error))
                .finally(() => setLoading(false))
            }}>
            <div>
                <Tabs variant="pills" defaultActiveKey="generalSettings" id="settings-tabs">
                    <Tab eventKey="generalSettings" title={t("General")}>
                        <div className="mt-3">
                            <GeneralSettingsComponent
                                currentSettings={currentSettings!}
                                changeSetting={changeSetting}
                                loading={loading}
                            />
                        </div>
                    </Tab>

                    <Tab eventKey="onboardingSettings" title={t("Onboarding")}>
                        <div className="mt-3">
                            <OnboardingSettingsComponent domain_id={domain_id!} />
                        </div>
                    </Tab>
                    <Tab eventKey="timeSeriesSettings" title={t("Timeseries")}>
                        <div className="mt-3">
                            <TimeSeriesSettingsComponent
                                currentSettings={currentSettings!}
                                changeSetting={changeSetting}
                                changeAttribute={changeAttribute}
                                removeAttribute={removeAttribute}
                                setAttributes={setAttributes}
                                attributes={attributes}
                                loading={loading}
                                domain_id={domain_id!}
                                errorMessage={errorMessage}
                                inKeyInput={inKeyInput}
                                storageKeyInput={storageKeyInput}
                                typeInput={typeInput}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </div>
            <div className="mb-4 d-flex justify-content-end" style={{ marginTop: "auto" }}>
            <Button 
                disabled={loading || !changesMade || Object.keys(errorMessage).length > 0} 
                variant="primary" 
                type="submit">
                { !loading && (<>{t("Apply")}</>) || (<><Spinner animation="border" size="sm" /> {t("Updating the settings")}... </>)}
            </Button>
            </div>
        </Form>
    </>
}